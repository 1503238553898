<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link @click="$router.push('/')" class="brand-logo">
      <vuexy-logo />
      <h2 class="brand-text text-primary ml-1">
        Lenon
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <label
          class="mb-1"
          style="font-size: 25px;"
        >
          <b>{{ schoolName }}</b> has not been verified yet!
        </label>
        <p class="mb-2">
          We are currently verifying your school based on the details you submitted, we will notify you once
          we are done.
          <b>If account has been verified but you are still seeing this page, please click on the refresh
            button.</b>
        </p>

        <lenon-button
          variant="primary"
          class="mb-2 btn-sm-block mr-2"
          icon="RefreshCcwIcon"
          label="Refresh"
          :loading="loading"
          @onClick="refresh"
        />

        <lenon-button
          variant="outline-danger"
          class="mb-2 btn-sm-block"
          icon="PowerIcon"
          label="Logout"
          @onClick="logout"
        />

        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
        />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import LenonButton from '@/lenon/components/LenonButton.vue'
import { CHECK_VERIFICATION_STATUS_Q, FETCH_REQUIRED_DATA_Q } from '@/graphql/queries'
import logData from '@/libs/log'
import showToast from '@/lenon/mixins/showToast'
import requiredData from '@/lenon/mixins/requiredData'
import logoutMixin from '@/lenon/mixins/logoutMixin'

export default {
  components: {
    LenonButton,
    VuexyLogo,
    BLink,
    BImg,
  },
  mixins: [
    showToast,
    requiredData,
    logoutMixin,
  ],
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
      loading: false,
    }
  },
  computed: {
    schoolName() {
      return this.$store.getters['auth/schoolName']
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  mounted() {
    this.checkVerificationStatus()
  },
  methods: {
    refresh() {
      this.checkVerificationStatus()
    },
    checkVerificationStatus() {
      this.loading = true
      this.$apollo.mutate({ mutation: CHECK_VERIFICATION_STATUS_Q })
        .then(res => {
          this.loading = false
          if (!res.errors && res.data.verificationStatus) {
            this.$store.commit('auth/updateSchool', { verified: res.data.verificationStatus })
            this.fetchRequiredData()
            this.$router.push({ name: 'home' })
          } else {

          }
        })
        .catch(err => {
          logData(err)
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-misc.scss';
</style>
